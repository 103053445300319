import React, { useState } from 'react';
import { LocalizedLink } from 'gatsby-theme-i18n';
import { useIntl } from 'react-intl';

import cx from 'classnames';

import * as styles from '../components/404/404.module.scss';

import Seo from '../components/seo/Seo';
import MobileMenu from '../components/mobile-menu/MobileMenu';
import Header from '../components/header/Header';

const NotFoundPage = () => {
  const intl = useIntl();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleToggle = () => {
    document.body.classList.toggle('modal-open');
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      <Seo />
      <MobileMenu isOpen={isMobileMenuOpen} toggleMenu={handleToggle} />

      <main className={styles.errorPage}>
        <Header toggleMenu={handleToggle} />

        <div className={styles.errorPage__content}>
          <div className="container">
            <div className={styles.errorPage__heading}>404</div>
            <h2 className={styles.errorPage__title}>
              {intl.formatMessage({ id: 'error.not-found' })}
            </h2>
            <LocalizedLink
              to="/"
              className={cx('btn  btn--bigger', styles.errorPage__btn)}
            >
              {intl.formatMessage({ id: 'error.to-home' })}
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.125 10.7852C0.125 11.418 0.863281 11.7344 1.32031 11.2773L5.82031 6.77734C6.10156 6.49609 6.10156 6.03906 5.82031 5.75781L1.32031 1.25781C0.863281 0.800781 0.125 1.11719 0.125 1.75V10.7852Z"
                  fill="white"
                />
              </svg>
            </LocalizedLink>
          </div>
        </div>
      </main>
    </>
  );
};

export default NotFoundPage;
